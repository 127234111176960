
	// Background Cover (ex: @extend %bg-cover;)

		%bg-cover {
			background: {
				position: center center;
				repeat: no-repeat;
				size: cover;
			}
		}

  // Dégradé Bleu foncé -> bleu ciel

    // %degrade {
    //   background-image: linear-gradient(to right, $$blue-500 10%, $$blue-300 100%)
    // }

  // Centrer verticalement (position absolute)

    // %center-vertically {
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    // }
