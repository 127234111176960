
  // Style pages d'article

    // Entête
    .page-entete {
      .page-entete__image {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 50vh;
        padding: 2rem;
        @extend %bg-cover;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          content: '';
          background: linear-gradient(to bottom, rgba($black, .9), rgba($black, .25));
        }

        h1 {
          position: relative;
          color: $white;
          z-index: 1;
        }
      }

      // Styles spécifiques aux pages

      &.page-les-brotteaux-entete {
        .page-entete__image {
          background-position: center bottom;
        }
      }

      &.page-les-travaux-apres-la-vente-de-la-sncf-entete {
        .page-entete__image {
          background-position: center bottom;
        }
      }
    }

    // Style fil d'ariane
    .page__fil-ariane {
      font-size: 1.3rem;
      color: $kaki-clair;
      background-color: $gris-blanc;

      li, a {
        display: inline-block;
      }

      a {
        padding: 1rem 1.5rem;
        cursor: pointer;
        text-decoration: none;
        border-right: .1rem solid $kaki-clair;
        color: $black;
        transition: all .3s ease-in-out;

        @media (max-width: $medium) {
          width: 100%;
          border-bottom: .1rem solid $kaki-clair;

          &.mid-list-item {
            border-right: none;
          }
        }

        @media (max-width: $tiny) {
          border-right: none;
        }

        &:hover {
          color: $white;
          background-color: $kaki-clair;
        }
      }

      ul {
        align-items: center;
        margin: 0;
        padding: 0;
      }

      li {
        @media (max-width: $medium) {
          flex-grow: 1;
        }

        @media (max-width: $tiny) {
          width: 100%;
        }

        &:last-of-type {
          padding: 1rem 1.5rem;

          @media (max-width: $medium) {
            width: 100%;
          }
        }
      }
    }

    // Corps article
    .article {
      font-size: 1.4rem;

      blockquote {
        margin: 0;
        font-size: 1.7rem;
        line-height: 1.8;

        @media (max-width: $medium) {
          padding-left: 2rem;
        }

        &:before {
          left: 0;
          top: -2rem;
          font-size: 25rem;
          color: $gris-clair;
          z-index: -1;

          @media (max-width: $medium) {
            font-size: 15rem;
          }
        }

        p:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .article-contenu {
      padding-bottom: 8rem;

      @media (max-width: $large-plus) {
        flex-direction: column;
      }

      @media (max-width: $large) {
        padding-bottom: 5rem;
      }

      @media (max-width: $small) {
        padding-bottom: 3rem;
      }
    }

    // Nav côté
    .article-contenu_nav {
      width: 30rem;

      @media (max-width: $large-plus) {
        width: 100%;
        order: 1;
        margin-top: 5rem;
      }

      @media (max-width: $large) {
        margin-top: 4rem;
      }

      @media (max-width: $small) {
        margin-top: 3rem;
      }

      &.article-contenu_nav__nav-meres {
        @media (max-width: $large-plus) {
          display: none;
        }
      }

      ul {
        margin: 0;
        padding: 0;

        @media (max-width: $large-plus) {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -1rem;
        }

        @media (max-width: $medium-plus) {
          margin: -1rem;
        }

        @media (max-width: $small) {
          margin: 0;
          justify-content: center;
        }
      }

      li {
        margin-bottom: 2rem;
        list-style-type: none;
        border: .2rem solid $kaki-clair;

        @media (max-width: $large-plus) {
          width: calc(33.33% - 2rem);
          margin: 0 1rem;
        }

        @media (max-width: $medium-plus) {
          width: calc(50% - 2rem);
          margin: 1rem;
        }

        @media (max-width: $small) {
          width: 100%;
          max-width: 35rem;
          margin: 0 0 2rem;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      a {
        display: block;
        text-decoration: none;

        &:hover {
          img {
            opacity: .8;
          }

          span {
            color: $white;
            background-color: $kaki-clair;
          }
        }
      }

      img {
        width: 100%;
        transition: opacity .3s ease-in-out;
      }

      span {
        display: block;
        padding: 1rem;
        text-transform: uppercase;
        font-size: 1.2rem;
        color: $kaki-fonce;
        background-color: transparent;
        transition: all .3s ease-in-out;
      }
    }

    .article-contenu_texte {
      width: calc(100% - 30rem);
      padding-left: 5rem;

      @media (max-width: $extra-large) {
        padding-left: 3rem;
      }

      @media (max-width: $large-plus) {
        width: 100%;
        padding-left: 0;
      }

      .article-contenu_texte__bloc {
        margin-bottom: 4rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      h3 {
        margin: 4rem 5rem 2rem;

        @media (max-width: $small) {
          margin: 3rem 2.5rem 2rem;
        }

        @media (max-width: $tiny) {
          margin: 3rem 0 1.5rem;
        }
      }

      .bloc_texte-wrapper {
        margin: 0 5rem 3rem;

        @media (max-width: $small) {
          margin: 0 2.5rem 3rem;
        }

        @media (max-width: $tiny) {
          margin: 0 0 3rem;
        }

        p {
          margin-bottom: 2rem;
          line-height: 1.8;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        a {
          text-decoration: underline;
          color: $kaki-fonce;

          &:hover {
            color: $kaki-clair;
          }
        }
      }

      img {
        width: 100%;
      }

      .legende {
        margin: 1rem 0 0;
        font-size: 1.3rem;
        text-align: right;
        font-style: italic;
      }
    }

    // Style liens vers pages filles

    .article-contenu_texte__bloc_liens .bloc_texte-wrapper {
      margin: 0 -1rem;

      @media (max-width: $medium-plus) {
        margin: -1rem;
      }

      @media (max-width: $small) {
        justify-content: center;
        margin: 0;
      }

      a {
        display: flex;
        flex-direction: column;
        width: calc(33.33% - 2rem);
        margin: 0 1rem;
        text-decoration: none;
        border: .2rem solid $kaki-fonce;

        @media (max-width: $medium-plus) {
          width: calc(50% - 2rem);
          margin: 1rem;
        }

        @media (max-width: $small) {
          width: 100%;
          max-width: 35rem;
          margin: 0 0 2rem;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &:hover span {
          color: $kaki-fonce;
          background-color: transparent;
        }
      }

      span {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        text-align: center;
        text-transform: uppercase;
        font-size: 1.2rem;
        color: $white;
        background-color: $kaki-fonce;
        transition: all .3s ease-in-out;
      }
    }

    // Style carrousel d'images

    .article-galerie-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: 0 2rem;

      @media (max-width: $large-plus) {
        justify-content: center;
        padding: 0;
      }
    }

    .article-galerie {
      width: 100%;
      margin: 0 -.5rem;

      @media (max-width: $large-plus) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        width: initial;
        margin: -1rem;
      }

      a {
        padding: 0 .5rem;
        text-decoration: none;
        outline: none;

        @media (max-width: $large-plus) {
          width: 33.33%;
          padding: 1rem;
        }

        @media (max-width: $medium) {
          width: 50%;
        }
      }

      img {
        width: 100%;
      }
    }

    .slick-track {
      margin: 0 0 0 auto !important;
    }

