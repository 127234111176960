// Style page d'accueil

  .intro-accueil {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 70vh;
    padding: 2rem;
    @extend %bg-cover;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      background: linear-gradient(to bottom, rgba($black, .7), rgba($black, .2));
    }

    .logo-brotteaux {
      position: relative;
      width: 35rem;
      height: 29rem;
      fill: $white;
      z-index: 1;

      @media (max-width: $medium) {
        width: 20rem;
        height: 16.6rem;
      }

      @media (max-width: $tiny) {
        width: 15rem;
        height: 12.4rem;
      }
    }
  }

  .contenu-accueil .container-large {
    padding-top: 8rem;

    @media (max-width: $large) {
      padding-top: 5rem;
    }

    @media (max-width: $small) {
      padding-top: 3rem;
    }

    h2 {
      margin-bottom: 1rem;
      font-weight: 300;
    }

    p {
      line-height: 1.8;
    }

    .vigns-article-wrapper:not(:last-of-type) {
      margin-bottom: 3rem;
    }

    .vign-article {
      position: relative;

      &:hover {
        &:before {
          opacity: 1;
        }

        .title-article span {
          background-color: transparent;
        }

        img {
          opacity: 1;
        }
      }

      &:first-of-type {
        margin-top: 1rem;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        opacity: 0;
        background: linear-gradient(to bottom, rgba($black, .8), rgba($black, .2));
        transition: opacity .3s ease-in-out;
        z-index: 1;
      }

      .link-article {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-decoration: none;
        z-index: 3;
      }

      .title-article {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        padding: 2rem;
        text-align: center;
        transform: translateY(-50%);
        z-index: 2;

        span {
          padding: 0 .5rem;
          text-transform: uppercase;
          font-weight: 300;
          font-size: 3rem;
          color: $white;
          background-color: $anthracite;
          transition: background-color .3s ease-in-out;

          @media (max-width: $large-plus) {
            font-size: 2.5rem;
          }

          @media (max-width: $medium) {
            font-size: 1.7rem;
          }
        }
      }

      img {
        width: 100%;
        opacity: .8;
        transition: opacity .3s ease-in-out;
      }

      &.vign-article_big {
        @media (min-width: $medium + 1) {
          margin-bottom: 2rem;
        }
      }
    }

    .flex-container {
      margin: 0 -1rem;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      transform: translateZ(0);

      .vign-article {
        padding: 0 1rem;

        &:before {
          left: 1rem;
          right: 1rem;
        }

        &.vign-article_small {
          flex-grow: 1;
          width: 33.33%;

          @media (max-width: $large-plus) {
            width: 50%;

            &:last-of-type {
              margin-top: 2rem;
            }
          }

          @media (max-width: $medium) {
            width: 100%;
            margin-top: 2rem;
          }

          .title-article span {
            font-size: 1.7rem;
          }
        }
      }
    }
  }
