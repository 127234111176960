
  // Style page Contact

    .page--contact {
      .page--contact_intro p {
        margin: 0;
        text-align: center;
        font-size: 1.7rem;
      }

      .container {
        padding-top: 8rem;

        @media (max-width: $large) {
          padding-top: 5rem;
        }

        @media (max-width: $small) {
          padding-top: 3rem;
        }
      }
    }

    .alert-wrapper {
      margin-bottom: 2rem;
    }

    .btn-submit {
      outline: none;
    }

    .page--contact__form {
      .form-groups-wrapper {
        margin: 0 -1rem;

        @media (max-width: $small) {
          margin: 0;
        }

        .form-group {
          width: calc(50% - 2rem);
          margin: 0 1rem 1rem;

          @media (max-width: $small) {
            width: 100%;
            margin: 0 0 1rem;
          }
        }
      }
    }
