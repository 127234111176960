
	// Styles Communs

		html {
			display: flex;
			flex-direction: column;
		}

		body {
			min-height: 100vh;
			display: flex;
			flex-direction: column;
      font: 300 1.6rem $font-stack-common;
      color: $anthracite;

      &.is-fixed {
        position: fixed;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
		}

		main {
			flex: 1 1 auto;
		}

    b, strong {
      font-weight: 700;
    }

    a {
      transition: color .3s ease-in-out;
    }

    ::selection {
      background: $gris-clair;
    }

  // Conteneurs

      %container {
        position: relative;
        margin: 0 auto;
        padding: 0 2rem;

        @include respond-to("tiny") {
          padding: 0 1.5rem;
        }
      }

      .container-tiny {
        max-width: 70rem;
        @extend %container;
      }

      .container-small {
        max-width: 90rem;
        @extend %container;
      }

      .container {
        max-width: 110rem;
        @extend %container;
      }

      .container-large {
        max-width: 130rem;
        @extend %container;
      }

  // Paddings

    .p-section {
      padding-top: 8rem;
      padding-bottom: 8rem;

      @media (max-width: $large) {
        padding-top: 5rem;
        padding-bottom: 5rem;
      }

      @media (max-width: $small) {
        padding-top: 3rem;
        padding-bottom: 3rem;
      }
    }

  // Titres

    h1 {
      margin: 0;
      font: 700 3rem $font-stack-common;
      text-transform: uppercase;
      letter-spacing: .1rem;
      text-align: center;

      @media (max-width: $medium) {
        font-size: 2.6rem;
      }

      @media (max-width: $tiny) {
        font-size: 2.1rem;
      }
    }

    h2 {
      margin-bottom: 2rem;
      font: 700 2.2rem $font-stack-common;
      text-transform: uppercase;
      letter-spacing: .1rem;

      @media (max-width: $small) {
        font-size: 2rem;
      }
    }

    h3 {
      margin-bottom: 2rem;
      font: 300 2.2rem $font-stack-common;
      text-transform: uppercase;
      letter-spacing: .1rem;

      @media (max-width: $small) {
        font-size: 2rem;
      }
    }

  // Iframe Responsive

    .iframe-wrapper {
      position: relative;
    }

    .iframe-sub-wrapper {
      position: relative;
      height: 0;
      padding: 0 0 56.25%; // Ratio 16/9

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
      }
    }

  // Style pages Mentions légales, Plan du site et Erreur 404

    .page-erreur-404 a, .page-mentions-legales a, .page-plan-du-site a {
      text-decoration: underline;
      color: $kaki-fonce;

      &:hover {
        color: $kaki-clair;
      }
    }

    .page-mentions-legales h2:not(:first-of-type) {
      margin-top: 3rem;
    }

    .page-plan-du-site-entete .page-entete__image {
      background-position: center bottom;
    }

    .page-plan-du-site li {
      margin-bottom: 1.5rem;

      &.li-parent {
        list-style: none;
      }
    }

