
  // Actualités

    .actualites {
      padding: 4rem 0;
    }

    .actualite__item {
      position: relative;

      a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }
    }

    .actualite__item-right {
      padding: 0 3rem;
      align-items: center;

      @media (max-width: $medium) {
        padding: 3rem 0;
        flex: initial;
      }
    }

  // Actualité

    .actualite-contenu_texte {
      width: calc(100% - 10rem);

      @media (max-width: $medium) {
        width: 100%;
      }

      h3 {
        margin: 0 0 2rem 0;
      }

      ul {
        li {
          margin-bottom: 1rem;
        }
      }
    }
