
	// Pied de page

  	.footer {
      color: rgba($white, .7);
  	}

    .footer-top > *, .footer-bottom > * {
      margin-bottom: 0;
      padding: 1rem;

      p {
        margin-bottom: 0;
      }
    }

    .footer-top {
      align-items: center;
      padding: 1rem;
      font-size: 1.3rem;
      background-color: $anthracite;

      .logo-wrapper {
        text-align: center;
        // width: 20%;

        @media (max-width: $xx-large) {
          p {
            max-width: 45rem;
            margin: 0 auto;
          }
        }

        .logo-brotteaux {
          width: 15rem;
          height: 12.4rem;
          margin-bottom: 1rem;
          fill: $kaki-clair;

          @media (max-width: $medium) {
            width: 10rem;
            height: 8.3rem;
          }
        }
      }

      .links-wrapper {
        width: 80%;
        padding: 2rem 1rem;

        @media (max-width: $xx-large) {
          width: 100%;
          padding: 0 1rem;
        }
      }

      .bloc-links {
        position: relative;
        width: calc(25% - 4rem);

        @media (min-width: $xx-large + 1) {
          padding: 1rem 0 1rem 4rem;
        }

        @media (max-width: $xx-large) {
          width: 25%;
          margin: 0;
          padding: 1rem 2rem;
        }

        @media (max-width: $large-plus) {
          width: 50%;
        }

        @media (max-width: $medium) {
          width: 100%;
        }

        @media (max-width: $small) {
          padding: 1rem 0;
        }

        &:not(:first-of-type) {

          @media (min-width: $xx-large + 1) {
            margin: 0 0 0 4rem;
          }

          @media (max-width: $xx-large) {
            margin: 0;
          }

          @media (max-width: $large-plus) and (min-width: $medium + 1) {
            &:nth-of-type(3):before {
              content: none;
            }
          }

          &:before {
            position: absolute;
            content: '';
            background-color: rgba($kaki-clair, .5);

            @media (min-width: $medium + 1) {
              top: 1.5rem;
              left: 0;
              bottom: 1.5rem;
              width: .1rem;
            }

            @media (max-width: $medium) {
              top: 0;
              left: 2.5rem;
              right: 2.5rem;
              height: .1rem;
            }

            @media (max-width: $small) {
              left: 0;
              right: 0;
            }
          }
        }
      }

      a {
        display: block;
        padding: .5rem .5rem .5rem 1rem;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: .05rem;
        color: inherit;

        &.title-link {
          padding-left: .5rem;
          font-weight: 700;
        }

        &:hover {
          color: $white;
        }
      }
    }

    .footer-bottom {
      padding: 0 1rem;
      font-size: 1.1rem;
      background-color: $black;

      @media (min-width: $tiny + 1) {
        .copyright {
          margin-right: auto;
        }
      }

      @media (max-width: $tiny) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: .5rem 1rem;
        text-align: center;

        > span {
          display: none;
        }

        .copyright {
          padding: .5rem;
        }
      }

      a, span {
        margin: 0 .5rem;
        text-decoration: none;
        color: $white;
        transition: color .3s ease-in-out;

        @media (max-width: $tiny) {
          padding: .5rem;
        }

        &:hover {
          color: $kaki-clair;
        }
      }

      .footer__btn-top {
        padding: 0 .75rem;
        outline: none;
        border: none;
        color: $white;
        background-color: transparent;
        transition: color .3s ease-in-out;

        @media (max-width: $tiny) {
          position: fixed;
          bottom: 0;
          right: 0;
          width: 3.1rem;
          text-align: center;
          background-color: $black;
        }

        &:hover {
          color: $kaki-clair;
        }
      }
    }
