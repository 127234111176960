
  // Navigation

    header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 3;

      &.is-mobile-nav-open .links-wrapper {
        pointer-events: auto;
        opacity: 1;
        z-index: 0;
      }
    }

    .nav {
      justify-content: space-between;
      padding: 1rem;

      .header-logo {
        position: relative;
        height: 5.9rem;
        padding: 1rem;
        z-index: 1;
      }

      .logo-brotteaux-texte {
        width: 12rem;
        fill: $white;
      }

      .links-wrapper {
        padding-top: .5rem;

        @media (max-width: $large-plus) {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100vh;
          pointer-events: none;
          opacity: 0;
          background: linear-gradient(to bottom, $anthracite, $kaki-clair);
          transition: opacity .3s ease-in-out;
          z-index: -1;
        }
      }

      .nav-link {
        position: relative;
        margin: 0 .5rem;
        font-size: 1.4rem;
        // padding: .2rem .5rem;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;
        color: $white;

        @media (max-width: $extra-large) {
          font-size: 1.5rem;
        }

        @media (max-width: $large-plus) {
          padding: 1rem;
          font-size: 1.7rem;
        }

        @media (max-width: $tiny) {
          padding: .5rem;
        }

        span {
          position: relative;
          color: inherit;
          z-index: 1;
        }

        &:after {
          position: absolute;
          left: .5rem;
          right: 100%;
          bottom: -.5rem;
          height: .3rem;
          content: '';
          background-color: $white;
          transition: right .5s $light-bounce;

          @media (max-width: $large-plus) {
            left: 1rem;
            bottom: .5rem;
          }

          @media (max-width: $tiny) {
            left: .5rem;
            bottom: 0;
          }
        }

        &:hover:after, &.is-active:after {
          right: .5rem;

          @media (max-width: $large-plus) {
            right: 1rem;
          }

          @media (max-width: $tiny) {
            right: .5rem;
          }
        }
      }
    }

    .hamburger {
      position: relative;
      justify-content: center;
      align-items: center;
      height: 3rem;
      outline: none;
      z-index: 1;

      @media (min-width: $large-plus + 1) {
        display: none;
      }

      .hamburger-label {
        margin-right: 1rem;
        text-transform: uppercase;
        color: $white;
      }
    }
