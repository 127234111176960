
	// Fonts (Polices de caractère)

		//// Slick

      @font-face {
        font-family: 'Slick';
          src: url('../fonts/slick/slick.woff') format('woff'),
               url('../fonts/slick/slick.ttf') format('truetype'),
               url('../fonts/slick/slick.svg#slick') format('svg');
      }

    //// Montserrat

      @font-face {
          font-family: 'Montserrat';
          src: url('../fonts/montserrat/montserrat-regular.woff2') format('woff2'),
               url('../fonts/montserrat/montserrat-regular.woff') format('woff'),
               url('../fonts/montserrat/montserrat-regular.ttf') format('truetype'),
               url('../fonts/montserrat/montserrat-regular.svg#MontserratRegular') format('svg');
          font-weight: 300;
          font-style: normal;
      }

      @font-face {
          font-family: 'Montserrat';
          src: url('../fonts/montserrat/montserrat-italic.woff2') format('woff2'),
               url('../fonts/montserrat/montserrat-italic.woff') format('woff'),
               url('../fonts/montserrat/montserrat-italic.ttf') format('truetype'),
               url('../fonts/montserrat/montserrat-italic.svg#MontserratItalic') format('svg');
          font-weight: 300;
          font-style: italic;
      }

      @font-face {
          font-family: 'Montserrat';
          src: url('../fonts/montserrat/montserrat-bold.woff2') format('woff2'),
               url('../fonts/montserrat/montserrat-bold.woff') format('woff'),
               url('../fonts/montserrat/montserrat-bold.ttf') format('truetype'),
               url('../fonts/montserrat/montserrat-bold.svg#MontserratBold') format('svg');
          font-weight: 700;
          font-style: normal;
      }

      @font-face {
          font-family: 'Montserrat';
          src: url('../fonts/montserrat/montserrat-bolditalic.woff2') format('woff2'),
               url('../fonts/montserrat/montserrat-bolditalic.woff') format('woff'),
               url('../fonts/montserrat/montserrat-bolditalic.ttf') format('truetype'),
               url('../fonts/montserrat/montserrat-bolditalic.svg#MontserratBoldItalic') format('svg');
          font-weight: 700;
          font-style: italic;
      }

